.main-navbar {
  display: flex;
  flex-direction: row;
  height: 100px;
  border-bottom: 2px solid rgb(255,95,46);
  font-weight: bold;
  font-size: 32px;
}

.far-left {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.far-right {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
}

.logo-navbar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-navbar-img {
  height: 80px;
  width: 80px;
  margin-right: 5px;
}

.logo-navbar-title-frog {
  color: rgb(255,95,46);
}

.connect-btn-navbar {
  cursor: pointer;
  margin-left: 20px;
  border-radius: 5px;
  border: solid;
  border-color: rgb(255, 95, 46);
  background-color: transparent;
  font-weight: bold;
  font-size: 32px;
  padding: 10px 20px;
}