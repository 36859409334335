.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url("../assets/frogGang.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.token-form {
  background-color: white;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 100px;
  padding: 20px;
  font-size: large;
}

form {
    display: flex;
    flex-direction: column;
}

input {
  width: 600px;
  background-color: rgb(250, 174, 151);
  border: none;
  border-radius: 8px;
  padding: 8px;
}

.connect-metamask {
    width: 600px;
    background-color: rgb(250, 174, 151);
    border: none;
    border-radius: 8px;
    padding: 8px;
  }

label {
  color: rgb(46, 105, 255);
}

.submit-btn {
  background-color: rgb(46, 105, 255);
  margin-top: 20px;
  width: 200px;
  height: 50px;
  color: white;
  cursor: pointer;
  align-self: center;
}

.cursor {
    cursor: pointer;
}