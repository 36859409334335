.main-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-image: url("../assets/frogsBuilding.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.title {
    margin-top: 100px;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 90px;
    line-height: .9em;
    text-align: center;
    font-weight: bold;
    background-color: white;
    border-radius: 50px;
}

.subtitle {
    margin-top: 10px;
    font-size: 25px;
}

.orange {
    color: rgb(255,95,46);
}

.blue {
    color: rgb(46,105,255);
}

.orange-background {
    background-color: rgb(255,95,46);
}

.blue-background {
    background-color: rgb(46,105,255);
}

.nav-btn-group {
    display: flex;
    margin-bottom: 50px;
}

.nav-btn {
    margin: 20px 20px;
    padding: 40px 50px;
    font-size: 30px;
    line-height: .9em;
    text-align: center;
    font-weight: bold;
    border-radius: 20px;
}

.socials-btn-group {
    background-color: white;
    border-radius: 50px;
    margin-bottom: 100px;
}

.socials-btn {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 30px;
    margin-right: 25px;
    height: 100px;
}