.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-image: url("../assets/cheeringFrogs.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.body {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 100px;
    padding: 20px;
    color: orange;
    font-size: large;
}

.orange {
    color: rgb(255,95,46);
}

.blue {
    color: rgb(46,105,255);
}

.orange-background {
    background-color: rgb(255,95,46);
}

.blue-background {
    background-color: rgb(46,105,255);
}

.nav-btn-group {
    align-self: center;
}